<template>
	<div class="container-fluid login_header">
		<div class="logo">
			<img src="/images/login_logo.png" alt="" class="img-fluid">
		</div>
		<a href="#" class="text-decoration-none"><div class="contact_btn fs-18">Contact US</div></a>
	</div>
	<section class="pb-40 sign-up-section">
		<div class="container h-100">
			<div class="row d-flex justify-content-center align-items-center h-100">
				<div class="col-12 col-md-8 col-lg-6 col-xl-6">
					<div class="box">
						<div class="box-body p-20 custom-form">
                        </div>
					</div>					
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: "Contact Us",
	components: {},
	data() {
		return {
			errors: null,
			form: {
				unique_id: null,
				email: null,
				password: null,
				pin: null,
			},
			loader: false,
			backEndError: ''
		};
	},
	created() {
		this.$store.state.loader = true;
		setTimeout(() => {
			this.$store.state.loader = false;
		}, 500);
	},
	mounted() { },
	methods: {},
	computed:{}
};
</script>
